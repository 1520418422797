
/* Contact Us Styles */
.contact-us-container {
  position: relative;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
  font-family: sans-serif
}

/* Banner styles */
.contact-us-banner {
  width: 100%;
  background-color: #f5f5f5;
  color: black;
  padding: 60px 20px;
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #ccc;
}

.banner-content {
  max-width: 1200px;
  margin: 0 auto;
  text-align: left;
}

.contact-us-banner h1 {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 20px;
}

.contact-us-banner p {
  font-size: 20px;
  line-height: 1.6;
  margin: 0;
}

/* Content styles */
.contact-us-content {
  width: 100%;
  max-width: 1200px;
  padding: 40px 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 40px;
}

.contact-form, .contact-info {
  background: white;
  border-radius: 10px;
  padding: 40px 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1;
}

.contact-form h2, .contact-info h2 {
  color: black;
  font-size: 28px;
  margin-bottom: 20px;
}

/* Form styles */
.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

.form-group input, .form-group textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
  transition: box-shadow 0.3s;
}

.form-group input:hover, .form-group textarea:hover {
  box-shadow: 0 0 8px rgba(0, 0, 255, 0.3);
}

.form-group textarea {
  resize: vertical;
}

.submit-button {
  background-color: #007bff;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  display: block;
  margin: 20px 0;
  position: relative;
}

.submit-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Info card styles */
.contact-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.info-card {
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  display: flex;
  align-items: center;
  gap: 15px;
  transition: transform 0.3s;
}

.info-card:hover {
  transform: scale(1.05);
}

.info-card .icon {
  font-size: 30px;
  color: #007bff;
}

.info-details h3 {
  color: #333;
  font-size: 22px;
  margin-bottom: 5px;
  font-weight: normal;
}

.info-details p {
  color: #555;
  font-size: 16px;
  line-height: 1.6;
  margin: 0;
  font-weight: bold;
}

.info-card i {
  font-size: 24px;
  color: #007bff;
}

.contact-link {
  color: #007bff;
  text-decoration: none;
}

.contact-link:hover {
  text-decoration: underline;
}

/* Map Section */
.map-section {
  width: 100%;
  max-width: 1200px;
  margin-top: 40px;
}

/* Mobile Specific Styling */
@media (max-width: 768px) {

  /* Adjust banner padding and font sizes */
  .contact-us-banner {
    padding: 40px 30px; /* Increase left and right padding */
  }

  .contact-us-banner h1 {
    font-size: 32px; /* Reduce font size for mobile */
    padding-left: 10px; /* Add some padding to prevent text touching the edges */
    padding-right: 10px;
  }

  .contact-us-banner p {
    font-size: 16px; /* Adjust font size for mobile */
    padding-left: 10px; /* Add padding for the paragraph as well */
    padding-right: 10px;
  }

  /* Adjust form and info card width */
  .contact-us-content {
    flex-direction: column; /* Stack items vertically */
    padding: 20px 20px; /* Increase left and right padding */
    gap: 20px; /* Reduce gap for mobile */
  }

  .contact-form, .contact-info {
    padding: 20px; /* Reduce padding inside cards */
    flex: 1 100%; /* Take full width on mobile */
  }

  /* Adjust form input and text area padding */
  .form-group input, .form-group textarea {
    padding: 10px; /* Reduce padding inside inputs */
  }

  /* Adjust submit button size */
  .submit-button {
    padding: 12px 20px; /* Reduce button size for mobile */
    font-size: 16px; /* Adjust font size for mobile */
  }

  /* Adjust info card layout */
  .info-card {
    padding: 15px; /* Reduce padding for mobile */
    gap: 10px; /* Reduce gap between icon and text */
    flex-direction: row; /* Stack horizontally still for mobile */
  }

  .info-card .icon {
    font-size: 24px; /* Reduce icon size */
  }

  .info-details h3 {
    font-size: 18px; /* Reduce font size for mobile */
  }

  .info-details p {
    font-size: 14px; /* Adjust font size for mobile */
  }

  /* Reduce the margin and padding for the map section */
  .map-section {
    margin-top: 20px;
    padding: 0 15px; /* Adjust padding to fit mobile screen */
  }
}
