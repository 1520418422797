/* General Navbar Styles */
.navbar {
  width: 100%;
  display: flex;
  justify-content: center;
  background: white;
  padding: 1em 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
  font-family:sans-serif;
  transition: top 0.3s ease-in-out;
}

.navbar.hidden {
  top: -100px;
}

.navbar.visible {
  top: 0;
}

.navbar-container {
  width: 100%;
  max-width: 1400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3em;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  height: 70px;
}

.cormix-logo {
  margin-left: 15px; /* Adjust spacing between the two logos */
  height: 40px; /* Make the Cormix logo 30px for desktop */
  cursor: pointer;
}

/* Desktop Nav Links */
.nav-links {
  display: flex;
  align-items: center;
  gap: 2.5em;
}

.nav-links a {
  color: #333;
  text-decoration: none;
  font-size: 1em;
  padding: 0.5em 1em;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.nav-links a:hover,
.nav-links a.active {
  color: #007bff;
  background: rgba(0, 123, 255, 0.1);
}

/* Services and Products Dropdown for Desktop */
.services-dropdown, .products-dropdown {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  background: #ffffff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 1em 1.5em;
  z-index: 10;
  min-width: 300px;
  display: none; /* Initially hidden */
}

.services-dropdown:hover .dropdown-menu,
.products-dropdown:hover .dropdown-menu {
  display: block; /* Show dropdown on hover */
}

.dropdown-menu a {
  color: #333;
  display: flex;
  align-items: center;
  padding: 0.8em 0;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  margin-bottom: 0.5em;
  border-radius: 5px;
}

.dropdown-menu a:hover {
  background-color: rgba(0, 123, 255, 0.1);
  color: #007bff;
}

/* Hamburger Menu for mobile */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  gap: 4px; /* Reduce the space between the bars */
  position: absolute;
  right: 50%; /* Align center */
  transform: translateX(50%); /* Centering the hamburger */
}

.hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: #333;
  transition: all 0.3s ease;
}

/* Mobile Menu */
.mobile-menu {
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translateX(-50%); /* Center the menu */
  width: 90%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em 0;
  gap: 25px; /* Equal spacing between elements */
  z-index: 999;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.mobile-menu a {
  color: #333;
  text-decoration: none;
  font-size: 1.2em;
  padding: 0.5em 1em;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.mobile-menu a:hover,
.mobile-menu a.active {
  color: #007bff;
  background: rgba(0, 123, 255, 0.1);
}

/* Mobile specific styles */
@media (max-width: 768px) {
  .navbar-container {
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
  }

  .logo-container {
    flex-direction: column; /* Stack logos vertically */
    align-items: flex-start;
  }

  .logo {
    height: 50px; /* Smaller size for mobile */
    margin-bottom: 5px;
  }

  .cormix-logo {
    height: 30px; /* Ensure both logos are of equal height */
    margin-bottom: 5px; /* Space between the logos */
  }

  .nav-links {
    display: none;
  }

  /* Hamburger Icon Styling */
  .hamburger {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
    margin-left: auto; 
    position: absolute;
    right: 15px; /* Add padding to the right to avoid leaving the screen */
    top: 50%; /* Vertically center it */
    transform: translateY(-50%); /* Align vertically in the middle of the navbar */
  }

  .hamburger .bar {
    width: 25px;
    height: 3px;
    background-color: #333;
    margin: 2px 0; /* Reduce space between the bars */
    transition: all 0.3s ease;
  }

  /* Adjust the mobile menu to ensure no overflow */
  .mobile-menu {
    left: 50%;
    transform: translateX(-50%);
  }
}

/* Arrow Styling */
.arrow {
  font-size: 0.6em;
  margin-left: 0.3em;
  color: #555;
  opacity: 0.7;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.services-dropdown:hover .arrow,
.products-dropdown:hover .arrow {
  transform: rotate(180deg);
  opacity: 1;
}

.nav-icon {
  width: 20px;
  height: auto;
  margin-right: 8px;
  vertical-align: middle;
}
