body {
  margin: 0;
  padding: 20px; /* Add padding to the page */
}

.card-container {
  display: flex;
  justify-content: center; /* Center the cards */
  flex-wrap: wrap; /* Allow cards to wrap to the next row if necessary */
  gap: 20px; /* Adjust spacing between cards */
  padding: 0 20px; /* Add padding to the left and right of the container */
}

.card {
  flex: 0 0 calc(30% - 20px); /* Adjust card width with reduced spacing */
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border-radius: 8px; /* Optional: Add rounded corners */
  padding: 4px; /* Reduce padding for better alignment */
}

.card-content {
  padding: 10px; /* Reduced padding inside the card for alignment */
  text-align: center;
}

/* Mobile Specific Styling */
@media (max-width: 768px) {
  .card-container {
    flex-direction: column; /* Stack cards in a column */
    align-items: center; /* Center-align the cards */
    gap: 20px; /* Maintain spacing between the cards */
    padding: 10px;
  }

  .card {
    width: 100%; /* Make each card take full width in the container */
    padding: 4px; /* Reduce padding on mobile for better alignment */
  }
}


.card-image {
  width: 100%;
  max-height: 200px; /* Reduce image height */
  object-fit: contain; /* Maintain aspect ratio */
}

.card-heading {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.card-text {
  font-size: 1rem;
  color: #333;
  margin-bottom: 10px;
  font-family: 'Arial', sans-serif; /* Choose a suitable font-family */
}

.card-button {
  background-color: #333;
  color: white;
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
  border: none;
  border-radius: 5px; /* Optional: Rounded button corners */
}

@media (max-width: 768px) {
  .card {
    width: 100%; /* Ensure the card takes the full width on mobile */
    padding: 8px; /* Adjust padding for mobile */
    box-sizing: border-box; /* Include padding in the width calculation */
  }

  .card-image {
    max-height: 150px; /* Reduce image height for mobile */
  }

  .card-heading {
    font-size: 1.2rem; /* Adjust heading size for mobile */
    margin-bottom: 8px;
  }

  .card-text {
    font-size: 0.9rem; /* Adjust text size for mobile */
  }
}