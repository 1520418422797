@keyframes sliderAnimation {
  0% { background-image: url('../Assets/Images/DisplayImages/basement-crystalline-waterproofing-without-membranes.webp'); }
  25% { background-image: url('../Assets/Images/DisplayImages/crystalline-waterproofing-25-years-warranty.webp'); }
  50% { background-image: url('../Assets/Images/DisplayImages/crystalline-waterproofing-save-time.webp'); }
  75% { background-image: url('../Assets/Images/DisplayImages/liquid-crystalline-admixture.webp'); }
  100% { background-image: url('../Assets/Images/DisplayImages/basement-crystalline-waterproofing-without-membranes.webp')}
}

/* Reset some default styling */
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: sans-serif
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Roboto';
}

/* Style for the fullscreen background image */
.fullscreen-image {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  animation: sliderAnimation 20s infinite;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4); 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
}

.overlay h1 {
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 1rem;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

.overlay p {
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 1px;
  margin-top: 0.5rem;
}

.contact-us-button {
  padding: 20px 40px;
  font-size: 1.5rem;
  background-color: #ffffff;
  color: #000;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  position: relative;
  overflow: hidden;
}

.contact-us-button:hover {
  background-color: #f0f0f0;
}

/* Home Content */
.home-content {
  padding: 20px;
  flex: 1;
}

.card-container {
  display: flex;
  justify-content: center; /* Center the cards */
  flex-wrap: wrap; /* Ensure cards wrap to the next row if necessary */
  gap: 20px; /* Add spacing between the cards */
  margin-top: 20px;
}

.card-container .card {
  width: 100%; /* Set full width for better responsiveness */
  max-width: 300px; /* Limit the width of each card */
  margin: 10px;
  text-align: center;
}

.card-container .card img {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.about-us-section {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  justify-content: center;
  padding: 30px 20px;
  background-color: #101d2c;
  border-radius: 10px;
  margin: 40px 0;
}

.about-us-image {
  flex: 0 0 400px;
  display: flex;
  align-items: stretch;
}

.about-us-image img {
  width: 400px; /* Set a fixed width */
  height: 100%; /* Stretch the height to fit the container */
  object-fit: cover; /* Ensures the image covers the container */
  border-radius: 10px;
  margin-right: 20px;
}

.about-us-content {
  max-width: 520px;
  text-align: left;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.about-title {
  font-size: 25px;
  margin-bottom: 15px;
  color: #ffffff;
  position: relative;
}

.about-title::after {
  content: '';
  position: absolute;
  width: 120px;
  height: 4px;
  background-color: #007bff;
  bottom: -10px;
  left: 0;
}

.about-description {
  font-size: 1.25rem;
  font-weight: 370;
  color: #ffffff;
  line-height: 1.8;
  margin-top: 20px;
  padding-right: 25px;
}

.read-more-button {
  padding: 10px 20px;
  font-size: 1.1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.read-more-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

/* Clients Section */
.clients-section {
  background-color: #f9f9f9;
  padding: 40px;
  border-radius: 10px;
  margin: 40px 0;
}

.clients-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

.section-title {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: #007bff;
}

/* Process Section */
.process-section {
  background-color: #101d2c;
  color: #fff;
  padding: 60px;
  border-radius: 10px;
  margin: 40px 0;
}

.process-container {
  max-width: 800px;
  margin: 0 auto;
}

.process-section h2.section-title {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 30px;
  background: linear-gradient(to right, #ffffff, #cccccc);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  position: relative;
}

.process-section h2.section-title::after {
  content: '';
  position: absolute;
  width: 100px;
  height: 3px;
  background-color: #007bff;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
}

.process-step {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.process-step-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.process-icon {
  width: 40px;
  height: auto;
  margin-right: 15px;
  filter: brightness(0) invert(1);
}

.process-step h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #ffffff;
  margin: 0;
}

.process-step p {
  font-size: 1rem;
  color: #ffffff;
  line-height: 1.6;
  margin-left: 55px;
}

/* Clients Slider */
.clients-slider-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  text-align: center;
  margin: 40px 0;
}

.clients-slider {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.client-logo {
  width: 150px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.client-logo:hover {
  transform: scale(1.05);
}

.client-logo img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.see-all-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #0056b3;
  color: #ffffff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.see-all-button:hover {
  background-color: #003f7f;
}

.clients-slider-heading {
  font-size: 28px;
  margin-bottom: 40px;
  color: #101d2c;
  background: linear-gradient(to right, #333, #111);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  position: relative;
}

.clients-slider-heading::after {
  content: '';
  position: absolute;
  width: 120px;
  height: 4px;
  background-color: #007bff;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

/* Scroll behavior */
html {
  scroll-behavior: smooth;
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
  .about-us-section {
    flex-direction: column;
    align-items: center;
  .card-container {
    justify-content: center; /* Ensure cards are centered on smaller screens */
  }
  .clients-section {
    display: none; /* Hide the entire section on mobile */
  }
  .about-us-section {
    display: none; /* Hide About Us section on mobile */
  }
}

  .about-us-content {
    max-width: 100%;
    text-align: center;
    padding-right: 0;
  }

  .about-title {
    font-size: 20px;
  }

  .about-description {
    font-size: 1rem;
  }

  .clients-slider {
    gap: 10px;
  }

  .client-logo {
    width: 120px;
    height: 80px;
  }

  .process-step p {
    margin-left: 0;
  }

  .process-section h2.section-title {
    font-size: 22px;
  }

  .process-step h3 {
    font-size: 1.2rem;
  }

  .contact-us-button {
    padding: 15px 30px;
    font-size: 1.2rem;
  }
}
