.about-container {
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
}

.about-content {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  max-width: 1200px;
}

.about-text {
  flex: 2;
  max-width: 600px;
  color: #333;
  margin-left: 20px;
}

.about-text h1 {
  font-size: 28px;
  margin-bottom: 20px;
  color: #101d2c;
  background: linear-gradient(to right, #333, #111);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  position: relative;
}

.about-text h1::after {
  content: '';
  position: absolute;
  width: 120px;
  height: 4px;
  background-color: #007bff;
  bottom: -10px;
  left: 0;
  transform: none;
}

.about-text p {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
}

.cormix-link {
  color: #007bff;
  text-decoration: none;
}

.cormix-link:hover {
  text-decoration: underline;
}

.about-cards {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.card-component {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s;
}

.card-component:hover {
  transform: scale(1.05);
}

.card-component img {
  width: 100%;
  height: auto;
}

.card-component .card-content {
  padding: 20px;
}

.card-component .card-heading {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #007bff;
}

.card-component .card-description {
  font-size: 16px;
  line-height: 1.4;
  color: #555;
}

.contact-button {
  background-color: #007bff;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin: 20px auto;
  display: block;
  text-align: center;
}

.contact-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Mobile Specific Styling */
@media (max-width: 768px) {
  .about-container {
    padding: 20px;
    align-items: center;
  }

  .about-content {
    flex-direction: column; /* Stacks items for mobile */
    align-items: center;
    gap: 20px; /* Reduced gap for mobile */
  }

  .about-text {
    max-width: 100%;
    margin: 0;
    text-align: center; /* Center text for mobile view */
  }

  .about-text h1 {
    font-size: 24px; /* Adjusted font size for mobile */
    text-align: center; /* Center align for mobile */
  }

  .about-text h1::after {
    left: 50%;
    transform: translateX(-50%); /* Center the underline for mobile */
  }

  .about-text p {
    font-size: 16px; /* Adjust font size for mobile readability */
    line-height: 1.5; /* Slightly tighter line spacing for mobile */
  }

  .about-cards {
    width: 100%; /* Cards take up full width on mobile */
    align-items: center;
  }

  .card-component {
    width: 90%; /* Make card fit better on mobile */
    max-width: none; /* Allow full-width for mobile view */
  }

  .card-component img {
    width: 100%;
    height: auto; /* Ensure images are properly scaled for mobile */
    display: block; /* Fix display issues on mobile */
  }

  .card-component .card-heading {
    text-align: center; /* Center heading for mobile */
  }

  .contact-button {
    width: 90%; /* Full width button for mobile */
    font-size: 16px; /* Adjust button font size for mobile */
  }
}
