/* Desktop Version - Keep as is */
.products-container {
  padding: 20px 10px; 
  text-align: center;
  margin-bottom: 40px; 
}

.products-container h1 {
  font-size: 28px;
  margin-bottom: 20px;
  color: #101d2c;
  background: linear-gradient(to right, #333, #111);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  position: relative;
}

.products-container h1::after {
  content: '';
  position: absolute;
  width: 120px;
  height: 4px;
  background-color: #007bff;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.products-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  justify-items: center;
  padding: 0 10px;
}

.product-card {
  background: white;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  width: 100%;
  max-width: 300px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.product-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.product-card img {
  width: 100%;
  height: 200px;
  object-fit: contain;
  margin-bottom: 10px;
  border-radius: 10px;
}

.button-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 10px;
}

.tds-button, .msds-button {
  background-color: #007bff;
  color: white;
  padding: 10px 18px;
  border: none;
  border-radius: 6px;
  font-size: 15px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  width: 100%;
}

.tds-button:hover, .msds-button:hover {
  background-color: #0056b3;
  transform: translateY(-3px);
}

/* Mobile Version Optimization */
@media (max-width: 768px) {
  .products-grid {
    grid-template-columns: 1fr; /* Single column for mobile */
  }

  .product-card {
    max-width: 100%; /* Full width for mobile */
  }

  .product-card img {
    height: 150px; /* Adjusted height for mobile */
  }

  .button-container {
    gap: 5px; /* Reduce space between buttons on mobile */
  }
}
