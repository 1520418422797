/* General Layout and Style */
.concrete-leakage-container {
    padding: 20px; /* Reduced padding for smaller screens */
    max-width: 1200px;
    margin: 0 auto;
    background-color: #f9f9f9;
    color: #333;
    text-align: center;
}

/* Updated heading styles */
.concrete-leakage-container h1,
.concrete-leakage-container h2 {
    font-size: 28px;
    margin-bottom: 40px;
    color: #101d2c;
    background: linear-gradient(to right, #333, #111);
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    position: relative;
}

.concrete-leakage-container h1::after,
.concrete-leakage-container h2::after {
    content: '';
    position: absolute;
    width: 120px;
    height: 4px;
    background-color: #007bff;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
}

.concrete-leakage-container p {
    font-size: 18px;
    line-height: 1.8;
    margin-bottom: 20px;
    text-align: justify;
}

/* Hover Effect for Images */
.concrete-leakage-image-gallery {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-bottom: 40px;
    flex-wrap: wrap; /* Allow images to wrap on smaller screens */
}

.concrete-leakage-image-gallery img {
    width: 100%;
    height: auto;
    max-width: 350px;
    border-radius: 10px;
    border: 2px solid #007bff;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}

.concrete-leakage-image-gallery img:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

/* List Styling */
ul {
    list-style: none;
    padding: 0;
}

ul li {
    font-size: 18px;
    padding: 10px;
    background: rgba(0, 123, 255, 0.1);
    margin: 10px 0;
    border-radius: 8px;
    transition: background-color 0.3s ease;
}

ul li:hover {
    background-color: rgba(0, 123, 255, 0.2);
}

/* Button Styling */
.contact-button {
    background-color: #007bff;
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    margin-top: 40px;
}

.contact-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

/* Scroll Animation */
.hidden {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.show {
    opacity: 1;
    transform: translateY(0);
}

/* Recent Projects Section */
.recent-projects-gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns on large screens */
    gap: 30px;
    margin-top: 40px;
}

.project-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
    border: 2px solid #007bff;
}

.project-card img {
    width: 100%;
    height: 320px; /* Increased height */
    object-fit: cover;
    border-bottom: 3px solid #007bff;
}

.project-card h3 {
    font-size: 1.4rem;
    margin: 10px 0 5px;
    color: #101d2c;
}

.project-card p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 15px;
    text-align: center;
}

.project-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Responsive Design */
@media (max-width: 1024px) {
    .recent-projects-gallery {
        grid-template-columns: repeat(2, 1fr); /* 2 columns on medium screens */
    }
}

@media (max-width: 768px) {
    .recent-projects-gallery {
        grid-template-columns: 1fr; /* 1 column on small screens */
    }

    .concrete-leakage-container {
        padding: 10px; /* Reduced padding for mobile */
    }

    .concrete-leakage-container h1,
    .concrete-leakage-container h2 {
        font-size: 24px; /* Adjust font size for mobile */
    }

    .concrete-leakage-container p {
        font-size: 16px; /* Smaller font size for mobile */
    }

    .contact-button {
        padding: 12px 25px; /* Adjust button size for mobile */
        font-size: 16px;
    }

    .concrete-leakage-image-gallery {
        flex-direction: column; /* Stack images vertically on mobile */
    }

    .concrete-leakage-image-gallery img {
        max-width: 100%; /* Allow images to take full width on small screens */
    }
}

/* Mobile-specific adjustments */
@media (max-width: 600px) {
    .recent-projects-gallery {
        grid-template-columns: 1fr; /* Single column for mobile */
        gap: 20px; /* Reduce gap on mobile */
    }

    .project-card img {
        height: 250px; /* Adjust image height for mobile */
    }

    .project-card h3 {
        font-size: 1.2rem; /* Slightly smaller font size for mobile */
    }

    .project-card p {
        font-size: 0.9rem; /* Smaller font size for better readability */
    }

    ul li {
        font-size: 16px; /* Reduce list item font size on mobile */
        padding: 8px;
    }
}
