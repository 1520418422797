.features-section {
  text-align: center;
  padding: 40px 20px;
}

.features-section h2 {
  font-size: 28px;
  margin-bottom: 40px;
  color: #101d2c;
  background: linear-gradient(to right, #333, #111);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  position: relative;
}

.features-section h2::after {
  content: '';
  position: absolute;
  width: 120px;
  height: 4px;
  background-color: #007bff;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.features-grid {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap; /* Allow cards to wrap to the next row on smaller screens */
}

.feature-card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  width: 250px;
  text-align: center;
  transition: transform 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-10px);
}

.feature-icon {
  font-size: 3rem;
  margin-bottom: 10px;
}

.feature-card h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.feature-card p {
  font-size: 1rem;
  color: #555;
}

/* Responsive Styles for Mobile */
@media (max-width: 768px) {
  .features-grid {
    flex-direction: column; /* Stack the feature cards vertically */
    align-items: center; /* Center-align the feature cards */
  }

  .feature-card {
    width: 100%; /* Make the feature cards take full width */
    max-width: 300px; /* Add a max-width to prevent the card from being too wide */
    margin-bottom: 20px; /* Add space between cards on mobile */
  }

  .feature-icon {
    font-size: 2.5rem; /* Adjust icon size for smaller screens */
  }

  .feature-card h3 {
    font-size: 1.25rem; /* Adjust heading size for smaller screens */
  }

  .feature-card p {
    font-size: 0.9rem; /* Adjust paragraph size for smaller screens */
  }

  .features-section h2 {
    font-size: 24px; /* Adjust the section title font size for mobile */
  }
}

/* Responsive Styles for Mobile */
@media (max-width: 768px) {
  .features-grid {
    flex-direction: column; /* Stack the feature cards vertically */
    align-items: center; /* Center-align the feature cards */
  }

  .feature-card {
    width: 100%; /* Make the feature cards take full width */
    max-width: 300px; /* Add a max-width to prevent the card from being too wide */
    margin-bottom: 20px; /* Add space between cards on mobile */
  }

  .feature-icon {
    font-size: 2.5rem; /* Adjust icon size for smaller screens */
  }

  .feature-card h3 {
    font-size: 1.25rem; /* Adjust heading size for smaller screens */
  }

  .feature-card p {
    font-size: 0.9rem; /* Adjust paragraph size for smaller screens */
  }

  .features-section h2 {
    font-size: 24px; /* Adjust the section title font size for mobile */
  }
}

/* Additional adjustments for very small mobile screens */
@media (max-width: 480px) {
  .features-section h2 {
    font-size: 22px; /* Further reduce font size on very small screens */
  }

  .feature-card {
    padding: 5px; /* Reduce padding for feature cards on small screens */
  }

  .feature-icon {
    font-size: 2rem; /* Reduce the icon size further */
  }

  .feature-card h3 {
    font-size: 1.1rem; /* Slightly smaller heading size */
  }

  .feature-card p {
    font-size: 0.85rem; /* Further reduce paragraph font size */
  }
}