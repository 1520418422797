.certification-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
}

/* Heading styling from the products container */
.certification-heading {
  font-size: 28px;
  margin-bottom: 20px;
  color: #101d2c;
  background: linear-gradient(to right, #333, #111);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  position: relative;
}

.certification-heading::after {
  content: '';
  position: absolute;
  width: 120px;
  height: 4px;
  background-color: #007bff;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

/* CSS for DCL Certificate Box */
.dcl-certificate-box {
  display: inline-block;
  margin-top: 30px;
  padding: 15px 30px;
  background-color: #007bff; /* Blue background */
  color: #fff; /* White text */
  text-align: center;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none; /* Remove underline */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add a slight shadow */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.dcl-certificate-box:hover {
  background-color: #0056b3; /* Darker blue on hover */
  color: #fff; /* Ensure text stays white */
}

.certification-image {
  max-width: 60%; /* Adjust the maximum width to make the image smaller */
  height: auto;
  margin-top: 20px; /* Add margin to the top of the image */
  border: 2px solid #000; 
}
