/* Footer Styles */
.footer {
  background-color: #101d2c;
  color: #ffffff;
  padding: 60px 20px 60px 20px; /* Added sufficient padding for content */
  font-family: sans-serif;
  font-size: 14px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
}

.footer-column {
  flex: 1;
  padding: 0 20px;
  min-width: 200px;
  text-align: left;
}

.footer-column h2 {
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: #007bff;
  border-bottom: 2px solid #007bff;
  padding-bottom: 5px;
}

.footer-column ul {
  list-style-type: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.6;
  transition: color 0.3s;
}

.footer-column ul li a {
  text-decoration: none;
  color: #ffffff;
}

.footer-column ul li a:hover {
  color: #007bff;
}

.contact-column .contact-details {
  padding: 0;
  list-style-type: none;
}

.contact-column .contact-details li {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.6;
}

.copyright {
  text-align: center;
  margin-top: 30px;
  font-size: 0.9rem;
  color: #cccccc;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-column {
    text-align: center;
    padding: 20px 0;
  }

  .contact-details {
    text-align: center;
  }
}
