/* Services Container */
.services-container {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
}

.services-container h1 {
  font-size: 24px;
  margin-bottom: 30px;
  color: #101d2c;
  background: linear-gradient(to right, #333, #111);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  position: relative;
}

.services-container h1::after {
  content: '';
  position: absolute;
  width: 100px;
  height: 3px;
  background-color: #007bff;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
}

.services-content {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

.services-container .card {
  flex: 0 0 calc(30% - 20px); 
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin-bottom: 20px;
}

.services-container .card-image {
  width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: cover;
}

.services-container .card-content {
  padding: 15px;
  text-align: center;
}

.services-container .card-heading {
  font-size: 1.4rem;
  margin-bottom: 10px;
}

.services-container .card-text {
  font-size: 0.95rem;
  color: #333;
  margin-bottom: 10px;
  font-family: 'Arial', sans-serif;
}

.services-container .card-button {
  background-color: #333;
  color: white;
  padding: 5px 10px;
  margin-top: 10px;
  cursor: pointer;
  border: none;
}

/* Button Styling */
.contact-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin-top: 30px;
}

.contact-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Mobile Specific Styling */
@media (max-width: 768px) {
  .services-content {
    flex-direction: column; /* Switch layout to column on mobile */
    align-items: center; /* Center the cards */
  }

  .services-container .card {
    width: 100%; /* Full width on mobile */
    max-width: none;
  }

  .services-container .card-image {
    max-height: 150px; /* Reduce image height for mobile */
  }

  .services-container .card-content {
    padding: 10px;
  }

  .services-container .card-heading {
    font-size: 1.2rem;
  }

  .services-container .card-text {
    font-size: 0.9rem;
  }

  .contact-button {
    padding: 10px 20px;
    font-size: 14px;
    margin-top: 20px;
  }
}
