/* General Layout and Style */
.foundation-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f9f9f9;
  color: #333;
  text-align: center;
}

/* Updated heading styles */
.foundation-container h1,
.foundation-container h2 {
  font-size: 28px;
  margin-bottom: 40px;
  color: #101d2c;
  background: linear-gradient(to right, #333, #111);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  position: relative;
}

.foundation-container h1::after,
.foundation-container h2::after {
  content: '';
  position: absolute;
  width: 120px;
  height: 4px;
  background-color: #007bff;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.foundation-container p {
  font-size: 18px;
  line-height: 1.8;
  margin-bottom: 20px;
  text-align: justify;
}

/* Image Gallery Styling */
.foundation-image-gallery {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-bottom: 40px;
  flex-wrap: wrap; /* Allow images to wrap on smaller screens */
}

/* Apply 'object-fit: contain' only to the first image */
.foundation-image-gallery img:first-child {
  object-fit: contain;
  height: 250px; /* Ensure it has the same height */
  max-width: 320px; /* Set a maximum width */
}

/* Other images retain 'object-fit: cover' */
.foundation-image-gallery img {
  width: 100%;
  height: 250px; /* Set a fixed height */
  max-width: 320px; /* Set a maximum width */
  border-radius: 10px;
  border: 2px solid #007bff;
  padding: 5px;
  object-fit: cover; /* Default for other images */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.foundation-image-gallery img:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}


/* List Styling */
ul {
  list-style: none;
  padding: 0;
}

ul li {
  font-size: 18px;
  padding: 10px;
  background: rgba(0, 123, 255, 0.1);
  margin: 10px 0;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

ul li:hover {
  background-color: rgba(0, 123, 255, 0.2);
}

/* Button Styling */
.contact-button {
  background-color: #007bff;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin-top: 40px;
}

.contact-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Scroll Animation */
.hidden {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.show {
  opacity: 1;
  transform: translateY(0);
}

/* Recent Projects Section */
.recent-projects-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns on large screens */
  gap: 30px;
  margin-top: 40px;
}

.project-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  border: 2px solid #007bff;
}

.project-card img {
  width: 100%;
  height: 250px; /* Same height as in the other section */
  object-fit: cover; /* Ensure images fill the card */
  border-bottom: 3px solid #007bff;
}

.project-card h3 {
  font-size: 1.4rem;
  margin: 10px 0 5px;
  color: #101d2c;
}

.project-card p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 15px;
  text-align: center;
}

.project-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .recent-projects-gallery {
      grid-template-columns: repeat(2, 1fr); /* 2 columns for medium screens */
  }
}

@media (max-width: 768px) {
  .recent-projects-gallery {
      grid-template-columns: 1fr; /* 1 column for smaller screens */
  }
  .foundation-image-gallery img:first-child {
    width: 100%;
    height: 250px; /* Make the height equal to other images on mobile */
    max-width: none; /* Allow it to take full width */
}

  .foundation-container {
      padding: 10px; /* Reduced padding for mobile */
  }

  .foundation-container h1,
  .foundation-container h2 {
      font-size: 24px; /* Adjust font size for mobile */
  }

  .foundation-container p {
      font-size: 16px; /* Smaller font size for mobile */
  }

  .contact-button {
      padding: 12px 25px; /* Adjust button size for mobile */
      font-size: 16px;
  }

  .foundation-image-gallery {
      flex-direction: column; /* Stack images vertically on mobile */
  }

  .foundation-image-gallery img {
      max-width: 100%; /* Full width on small screens */
      height: auto; /* Maintain aspect ratio */
  }
}

/* Mobile-specific adjustments */
@media (max-width: 600px) {
  .recent-projects-gallery {
      grid-template-columns: 1fr; /* Single column for mobile */
      gap: 20px; /* Reduce gap on mobile */
  }

  .project-card img {
      height: 250px; /* Adjust image height for mobile */
  }

  .project-card h3 {
      font-size: 1.2rem; /* Slightly smaller font size for mobile */
  }

  .project-card p {
      font-size: 0.9rem; /* Smaller font size for better readability */
  }

  ul li {
      font-size: 16px; /* Reduce list item font size on mobile */
      padding: 8px;
  }
}
